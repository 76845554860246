import { Link } from 'gatsby';
import React from 'react';
import { StructuredText } from 'react-datocms';

const ProjectTitle = ({data, title, client}) => {
    return (
        <section className={`page-builder page-builder-project-title ${data.backgroundType}-${data.backgroundColourOne} ${data.backgroundType}-${data.backgroundColourOne}-${data.backgroundColourTwo} light-text-${data.lightText}`} >
            <div className='introduction-titles'>
                <h6 className='client-title'>
                    <Link to={`/client/${client.slug}`}>
                        <div className='client-logo' dangerouslySetInnerHTML={{ __html: client.logo }}></div>
                        {client.logo === '' &&
                            <>{client.name}</>
                        }
                    </Link>
                </h6>
                <h2>{title}</h2>
            </div>
            <div className='introduction-copy'>
                <StructuredText data={data.introduction}/>
            </div>
        </section>
    )
}

export default ProjectTitle