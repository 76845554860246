import * as React from 'react'
import { Player, BigPlayButton } from 'video-react';
import { EnteringSmall, LeavingSmall } from "./cursor"
// import "node_modules/video-react/dist/video-react.css"; // import css

const Video = ({data}) => {
    return (
        <div className='video-container' onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>
            <Player
                preload='metadata'
                playsInline
            >
                <source src={`${data.video}#t=0.1`} type='video/mp4'></source>
                <BigPlayButton position="center" />
            </Player>
        </div>
    )
}

export default Video