import React from 'react';
import { StructuredText } from 'react-datocms';
import { Link } from 'gatsby'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Arrow from '../svg/arrow';
import Quote from '../svg/quote';

import { EnteringSmall, LeavingSmall } from "./cursor"

const ProjectText = ({data}) => {
    return (
        <section className={`page-builder page-builder-project-text ${data.backgroundType}-${data.backgroundColourOne} ${data.backgroundType}-${data.backgroundColourOne}-${data.backgroundColourTwo} light-text-${data.lightText}`} >
        {data.content.map(content => (
            <>
                {content.__typename === 'DatoCmsColumnText' &&
                    <div className='columned-text'>
                        <div className='left'>
                            <StructuredText data={content.left} />
                        </div>
                        <div className='right'>
                            <StructuredText data={content.right} />
                        </div>
                    </div>
                }
                {content.__typename === 'DatoCmsText' &&
                    <div className='single-text'>
                        <StructuredText data={content.structuredText} />
                    </div>
                }
                {content.__typename === 'DatoCmsHeading' &&
                    <div className='single-text'>
                        <h1 className='centered'>{content.text}</h1>
                    </div>
                }
                {content.__typename === 'DatoCmsQuoteCarousel' &&
                    <div className='quote-carousel'>
                        <Swiper
                            spaceBetween={80}
                            centeredSlides={true}
                            slidesPerView={2.2}
                            loop={true}
                            speed={1000}
                            draggable={false}
                            pagination={{
                                clickable: false,
                                type: 'progressbar'
                            }}
                            navigation={{
                                prevEl: '.navigation-prev',
                                nextEl: '.navigation-next',
                            }}
                            modules={[Pagination, Navigation]}
                            breakpoints={{
                                0:{
                                    slidesPerView: 1.2
                                },
                                780: {
                                    slidesPerView: 1.5,
                                },
                                1080: {
                                    slidesPerView: 1.8,
                                    spaceBetween: 0
                                }
                            }}
                            className={`project-carousel project-carousel-${data.size}`}
                            >
                            {content.content.map(item => (
                                <SwiperSlide key={item.id} className="carousel-item">
                                    <blockquote>
                                        <p className='lede'>"{item.quote}"</p>
                                        <div className='quoter'>
                                            <Quote />
                                            <span className='subnavigation'>{item.quoter}</span>
                                        </div>
                                    </blockquote>
                                </SwiperSlide>
                            ))}
                            <div className={`navigation-prev ${data.originalId}`} onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>
                                <Arrow />
                            </div>
                            <div className={`navigation-next ${data.originalId}`} onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>
                                <Arrow />
                            </div>
                        </Swiper>
                    </div>
                }
                {content.__typename === 'DatoCmsLink' &&
                    <div className='single-button'>
                        <Link to={content.url} class="btn">
                            <span className="btn-text">{content.title}</span>
                        </Link>
                    </div>
                }
            </>
        ))}
        </section>
    )
}

export default ProjectText