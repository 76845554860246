import React from 'react';
import Video from './video';

const ProjectGrid = ({data}) => {
    return (
        <section className={`page-builder page-builder-project-grid page-builder-project-grid-${data.content.length} ${data.backgroundType}-${data.backgroundColourOne} ${data.backgroundType}-${data.backgroundColourOne}-${data.backgroundColourTwo} light-text-${data.lightText} ${data.gridType}`} >
            {data.content.map(media => (
                <div className='media-item'>
                    {media.__typename === 'DatoCmsImage' &&
                    <>
                        <img src={media.image.url} alt={media.image.alt} />
                    </>
                    }
                    {media.__typename === 'DatoCmsVideo' &&
                        <Video data={media} />
                    }
                </div>
            ))}
        </section>
    )
}

export default ProjectGrid