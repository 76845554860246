import React from 'react';
import Video from './video';

const ProjectSingle = ({data}) => {
    return (
        <section className={`page-builder page-builder-project-single ${data.backgroundType}-${data.backgroundColourOne} ${data.backgroundType}-${data.backgroundColourOne}-${data.backgroundColourTwo} light-text-${data.lightText} ${data.size}`} >
        {data.media.map(media => (
            <div className='media'>
                {media.__typename === 'DatoCmsImage' &&
                    <>
                    {data.size === 'full' && 
                        <img src={media.image.full} alt={media.image.alt} />
                    }
                    {data.size === 'large' && 
                        <img src={media.image.large} alt={media.image.alt} />
                    }
                    {data.size === 'medium' && 
                        <img src={media.image.medium} alt={media.image.alt} />
                    }
                    {data.size === 'small' && 
                        <img src={media.image.small} alt={media.image.alt} />
                    }
                    </>
                }
                {media.__typename === 'DatoCmsVideo' &&
                    <Video data={media} />
                }
                <div className='caption'>{data.caption}</div>
            </div>
        ))}
        </section>
    )
}

export default ProjectSingle