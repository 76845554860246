import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Arrow from '../svg/arrow';
import { EnteringSmall, LeavingSmall } from "./cursor"

const ProjectProcess = ({data}) => {
    return (
        <section className={`page-builder page-builder-project-carousel page-builder-project-process ${data.backgroundType}-${data.backgroundColourOne} ${data.backgroundType}-${data.backgroundColourOne}-${data.backgroundColourTwo} light-text-${data.lightText} ${data.size}`} >
            <div className='process-carousel'>
                <h2 className='title'>{data.heading}</h2>
                <Swiper
                    spaceBetween={80}
                    centeredSlides={true}
                    slidesPerView={2.8}
                    loop={false}
                    speed={1000}
                    draggable={true}
                    allowTouchMove={true}
                    pagination={{
                        clickable: false,
                        type: 'progressbar'
                    }}
                    navigation={{
                        prevEl: '.navigation-prev',
                        nextEl: '.navigation-next',
                    }}
                    modules={[Pagination, Navigation]}
                    breakpoints={{
                        0:{
                            slidesPerView: 1.2,
                            spaceBetween: 24
                        },
                        780: {
                            slidesPerView: 2,
                            spaceBetween: 40
                        },
                        1080: {
                            slidesPerView: 2.8
                        }
                    }}
                    className={`project-carousel`}
                >
                    {data.content.map((item, index) => (
                        <SwiperSlide key={item.id} className="carousel-item carousel-item-process">
                            <div className='process-item'>
                                <h5>{item.subheading}</h5>
                                <h3>{item.heading}</h3>
                                <p>{item.text}</p>
                                <svg>
                                    <rect width="100%" height="100%"></rect>
                                </svg>
                            </div>
                        </SwiperSlide>
                    ))}
                    <div className={`navigation-prev ${data.originalId}`} onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>
                        <Arrow />
                    </div>
                    <div className={`navigation-next ${data.originalId}`} onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>
                        <Arrow />
                    </div>
                </Swiper>
                <div className='background-image'>
                    <img alt={data.image.alt} class={`cover-image blur-${data.blur}`} src={data.image.url}></img>
                </div>
            </div>
        </section>
    )
}

export default ProjectProcess