import * as React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql, Link } from 'gatsby'

import ProjectTitle from "../components/project-title"
import ProjectSingle from "../components/project-single"
import ProjectCarousel from '../components/project-carousel'
import ProjectText from '../components/project-text'
import ProjectParentChild from '../components/project-parent-child'
import Layout from "../components/layout";
import ProjectOverlaid from '../components/project-overlaid'
import ProjectCredits from '../components/project-credits'
import ProjectPress from '../components/project-press'
import Footer from '../components/footer'
import ProjectGrid from '../components/project-grid'
import ProjectProcess from '../components/project-process'
import RelatedProject from '../components/related-project'

const Project = ({ data, id, pageContext }) => {
    const project = data.project
    const number = data.all.nodes.length
    const next = pageContext.next
            ? {
                url: `/project/${pageContext.next.slug}`,
                title: `${pageContext.next.title}`,
                client: `${pageContext.next.client.name}`,
                image: `${pageContext.next.image.url}`
            }
            : null
    const previous = pageContext.previous
            ? {
                url: `/project/${pageContext.previous.slug}`,
                title: `${pageContext.previous.title}`,
                client: `${pageContext.previous.client.name}`,
                image: `${pageContext.previous.image.url}`
            }
            : null
    const previousBackup = data.all.nodes[number - 1]
            ? {
                url: `/project/${data.all.nodes[number - 1].slug}`,
                title: `${data.all.nodes[number - 1].title}`,
                client: `${data.all.nodes[number - 1].client.name}`,
                image: `${data.all.nodes[number - 1].image.url}`
            }
            : null
    const nextBackup = data.all.nodes[0]
            ? {
                url: `/project/${data.all.nodes[0].slug}`,
                title: `${data.all.nodes[0].title}`,
                client: `${data.all.nodes[0].client.name}`,
                image: `${data.all.nodes[0].image.url}`
            }
    : null
    return (
        <Layout>
            {project.nodes.map(project => (
                <main>
                    <HelmetDatoCms 
                        seo={project.seoMetaTags}
                        bodyAttributes={{
                            class: 'light-bg project-page'
                        }}
                    />
                    {project.builder.map(block => (
                        <section>
                            {block.__typename === 'DatoCmsProjectTitle' &&
                                <ProjectTitle data={block} title={project.title} client={project.client} />
                            }
                            {block.__typename === 'DatoCmsProjectSingle' &&
                                <ProjectSingle data={block} />
                            }
                            {block.__typename === 'DatoCmsProjectCarousel' &&
                                <ProjectCarousel data={block} />
                            }
                            {block.__typename === 'DatoCmsProjectText' &&
                                <ProjectText data={block} />
                            }
                            {block.__typename === 'DatoCmsProjectParentChild' &&
                                <ProjectParentChild data={block} />
                            }
                            {block.__typename === 'DatoCmsProjectOverlaid' &&
                                <ProjectOverlaid data={block} />
                            }
                            {block.__typename === 'DatoCmsProjectCredit' &&
                                <ProjectCredits data={block} />
                            }
                            {block.__typename === 'DatoCmsProjectPress' &&
                                <ProjectPress data={block} />
                            }
                            {block.__typename === 'DatoCmsProjectGrid' &&
                                <ProjectGrid data={block} />
                            }
                            {block.__typename === 'DatoCmsProjectProcess' &&
                                <ProjectProcess data={block} />
                            }
                        </section>
                    ))}
                    <section className={`page-builder page-builder-project-related solid-${project.relatedColour}`}>
                        <h3 className='title'>{project.moreCta}</h3>
                        <div className='related-grid'>
                            {previous && 
                                <RelatedProject data={previous} />
                            }
                            {previous === null &&
                                <RelatedProject data={previousBackup} />
                            }
                            {next && 
                                <RelatedProject data={next} />
                            }
                            {next === null &&
                                <RelatedProject data={nextBackup} />
                            }
                        </div>
                    </section>
                    <Footer color={project.footerColour} />
                </main>
            ))}
        </Layout>
    );
}
export default Project

export const query = graphql`
    query Project
    (
    $id: String!) 
    {
        all: allDatoCmsProject(sort: {fields: position, order: ASC}) {
            nodes {
                slug
                title
                client {
                    name
                }
                image {
                    url(imgixParams: {w: "840"})
                }
            }
        }
        project: allDatoCmsProject(filter: {id: {eq: $id}}sort: {fields: position, order: ASC}) {
            nodes {
                id
                slug
                title
                footerColour
                relatedColour
                moreCta
                client {
                    name
                    slug
                    logo
                }
                seoMetaTags {
                    ...GatsbyDatoCmsSeoMetaTags
                }
                builder {
                    ... on DatoCmsProjectSingle {
                        id
                        backgroundColourOne
                        backgroundColourTwo
                        backgroundType
                        caption
                        lightText
                        size
                        __typename
                        media {
                        ... on DatoCmsImage {
                            __typename
                            image {
                                    full: url(imgixParams: {w: "1920"})
                                    large: url(imgixParams: {w: "1440"})
                                    medium: url(imgixParams: {w: "1080"})
                                    small: url(imgixParams: {w: "480"})
                                    alt
                                }
                            }
                        ... on DatoCmsVideo {
                            __typename
                            video
                            }
                        }
                        }
                    ... on DatoCmsProjectTitle {
                        id
                        backgroundColourOne
                        backgroundColourTwo
                        backgroundType
                        lightText
                        __typename
                        introduction {
                            value
                        }
                    }
                    ... on DatoCmsProjectCarousel {
                        id
                        originalId
                        backgroundColourOne
                        backgroundColourTwo
                        backgroundType
                        lightText
                        size
                        __typename
                        content {
                        ... on DatoCmsImage {
                            __typename
                            id
                            image {
                                full: url(imgixParams: {w: "1920"})
                                large: url(imgixParams: {w: "1440"})
                                alt
                            }
                        }
                        ... on DatoCmsVideo {
                            id
                            __typename
                            video
                        }
                        }
                    }
                    ... on DatoCmsProjectText {
                        id
                        __typename
                        backgroundColourOne
                        backgroundColourTwo
                        backgroundType
                        lightText
                        content {
                        ... on DatoCmsHeading {
                            id
                            __typename
                            text
                        }
                        ... on DatoCmsText {
                            id
                            __typename
                            structuredText {
                                blocks
                                links
                                value
                            }
                        }
                        ... on DatoCmsColumnText {
                            id
                            __typename
                            left {
                                blocks
                                links
                                value
                            }
                            right {
                                blocks
                                links
                                value
                            }
                        }
                        ... on DatoCmsQuoteCarousel {
                            id
                            __typename
                            content {
                                quote
                                quoter
                            }
                        }
                        ... on DatoCmsLink {
                            __typename
                            url
                            title
                            linkType
                        }
                        }
                    }
                    ... on DatoCmsProjectParentChild {
                        id
                        __typename
                        backgroundColourOne
                        backgroundColourTwo
                        backgroundType
                        lightText
                        orientation
                        content {
                        ... on DatoCmsImage {
                            __typename
                            id
                            image {
                                url(imgixParams: {w: "1280"})
                                alt
                                title
                                }
                            }
                        ... on DatoCmsVideo {
                            id
                            __typename
                            video
                            }
                        ... on DatoCmsText {
                            id
                            __typename
                            structuredText {
                                blocks
                                value
                                links
                                }
                            }
                        }
                    }
                    ... on DatoCmsProjectOverlaid {
                        id
                        __typename
                        backgroundColourOne
                        backgroundColourTwo
                        backgroundType
                        lightText
                        orientation
                        content {
                        ... on DatoCmsImage {
                            __typename
                            id
                            image {
                                url(imgixParams: {w: "1280"})
                                alt
                                title
                                }
                            }
                        ... on DatoCmsVideo {
                            id
                            __typename
                            video
                        }
                        }
                    }
                    ... on DatoCmsProjectCredit {
                        id
                        __typename
                        backgroundColourOne
                        backgroundColourTwo
                        backgroundType
                        lightText
                        title
                        orientation
                        content {
                        title
                        credits {
                            name
                            person
                            url
                        }
                        }
                    }
                    ... on DatoCmsProjectPress {
                        id
                        __typename
                        backgroundColourOne
                        backgroundColourTwo
                        backgroundType
                        lightText
                        title
                        orientation
                        content {
                            title
                            url
                        }
                    }
                    ... on DatoCmsProjectGrid {
                        __typename
                        id
                        backgroundColourOne
                        backgroundColourTwo
                        backgroundType
                        gridType
                        lightText
                        content {
                        ... on DatoCmsImage {
                            __typename
                            image {
                                url(imgixParams: {w: "1280"})
                                alt
                                title
                            }
                        }
                        ... on DatoCmsVideo {
                            id
                            __typename
                            video
                        }
                        }
                    }
                    ... on DatoCmsProjectProcess {
                        __typename
                        id
                        backgroundColourOne
                        backgroundColourTwo
                        backgroundType
                        heading
                        blur
                        image {
                            url(imgixParams: {w: "1440", blendColor: "2A292D", blendMode: "normal", blendAlpha: 56})
                            alt
                        }
                        content {
                            text
                            subheading
                            heading
                        }
                    }
                }
            }
        }
    }
`;