import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const ProjectCredits = ({data}) => {
    return (
        <section className={`page-builder page-builder-project-credits ${data.backgroundType}-${data.backgroundColourOne} ${data.backgroundType}-${data.backgroundColourOne}-${data.backgroundColourTwo} light-text-${data.lightText} ${data.orientation}`} >
            <h3 className='credit-title'>{data.title}</h3>
            <Accordion preExpanded="0" className="credit-container">
                {data.content.map((section, index) => (
                    <AccordionItem className="section-item" uuid={index}>
                        <AccordionItemHeading className="credit-item-heading">
                            <AccordionItemButton className="credit-item-button">
                                <h5 className="credit-item-question">{section.title}</h5>
                                <span className="credit-item-button-plus">
                                </span>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="credit-item-body">
                            <div className='credit-item-grid'>
                                {section.credits.map(credit => (
                                    <div className='credit-item'>
                                        <p className='bold'>{credit.name}</p>
                                        <p>
                                        {credit.url !== '' &&
                                            <a className="credit-link" href={credit.url} target="_blank" rel="norefferer">{credit.person}</a>
                                        }
                                        {credit.url === '' && 
                                            <>{credit.person}</>
                                        }
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </section>
    )
}

export default ProjectCredits