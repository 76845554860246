import { Link } from 'gatsby';
import React from 'react';

const ProjectPress = ({data}) => {
    return (
        <section className={`page-builder page-builder-project-press ${data.backgroundType}-${data.backgroundColourOne} ${data.backgroundType}-${data.backgroundColourOne}-${data.backgroundColourTwo} light-text-${data.lightText} ${data.orientation}`} >
            <h3 className='press-title'>{data.title}</h3>
            <div className='press-container'>
                {data.content.map(link => (
                    <a className='press-link' href={link.url} target="_blank" rel="norefferer">
                        <h5>{link.title}</h5>
                    </a>
                ))}
            </div>
        </section>
    )
}

export default ProjectPress