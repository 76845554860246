import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Arrow from '../svg/arrow';
import Video from './video';
import { EnteringSmall, LeavingSmall } from "./cursor"

const ProjectCarousel = ({data}) => {
    return (
        <section className={`page-builder page-builder-project-carousel ${data.backgroundType}-${data.backgroundColourOne} ${data.backgroundType}-${data.backgroundColourOne}-${data.backgroundColourTwo} light-text-${data.lightText} ${data.size}`} >
            {data.size === 'normal' && 
                <Swiper
                spaceBetween={0}
                centeredSlides={true}
                slidesPerView={1.6}
                loop={true}
                speed={1000}
                draggable={false}
                allowTouchMove={false}
                pagination={{
                    clickable: false,
                    type: 'progressbar'
                }}
                navigation={{
                    prevEl: '.navigation-prev',
                    nextEl: '.navigation-next',
                }}
                modules={[Pagination, Navigation]}
                breakpoints={{
                    0:{
                        slidesPerView: 1.2,
                        allowTouchMove: true
                    },
                    780: {
                        slidesPerView: 1.5,
                    },
                    1080: {
                        slidesPerView: 1.6,
                        allowTouchMove: false
                    }
                }}
                className={`project-carousel project-carousel-${data.size}`}
                >
                {data.content.map((item, index) => (
                    <SwiperSlide key={item.id} className="carousel-item">
                        {item.__typename === 'DatoCmsImage' &&
                            <img src={item.image.full} alt={item.image.alt} />
                        }
                        {item.__typename === 'DatoCmsVideo' &&
                            <Video data={item} />
                        }
                    </SwiperSlide>
                ))}
                <div className={`navigation-prev ${data.originalId}`} onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>
                    <Arrow />
                </div>
                <div className={`navigation-next ${data.originalId}`} onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>
                    <Arrow />
                </div>
                </Swiper>
            }
            {data.size === 'full' && 
                <Swiper
                spaceBetween={0}
                centeredSlides={true}
                slidesPerView={1}
                loop={true}
                speed={1000}
                draggable={false}
                pagination={{
                    clickable: false,
                    type: 'progressbar'
                }}
                navigation={{
                    prevEl: '.navigation-prev',
                    nextEl: '.navigation-next',
                }}
                modules={[Pagination, Navigation]}
                className={`project-carousel project-carousel-${data.size}`}
                >
                {data.content.map((item, index) => (
                    <SwiperSlide key={item.id} className="carousel-item">
                        {item.__typename === 'DatoCmsImage' &&
                            <img src={item.image.full} alt={item.image.alt} />
                        }
                        {item.__typename === 'DatoCmsVideo' &&
                            <Video data={item} />
                        }
                    </SwiperSlide>
                ))}
                <div className={`navigation-prev ${data.originalId}`}>
                    <Arrow />
                </div>
                <div className={`navigation-next ${data.originalId}`}>
                    <Arrow />
                </div>
                </Swiper>
            }
        </section>
    )
}

export default ProjectCarousel