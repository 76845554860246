import * as React from 'react'

const Quote = () => {
    return (
        <svg className="quote" width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.44031 1.7439C0.800313 5.4399 0.320312 6.9279 0.320312 9.6159V13.2639H7.56831V7.1199H3.96831V6.7359L8.19231 0.399902H4.35231L3.44031 1.7439ZM13.3283 1.7439C10.6883 5.4399 10.2083 6.9279 10.2083 9.6159V13.2639H17.4563V7.1199H13.8563V6.7359L18.0803 0.399902H14.2403L13.3283 1.7439Z" fill="#2A292D"/>
        </svg>
    )
}

export default Quote