import { Link } from 'gatsby'
import * as React from 'react'
import { EnteringView, LeavingView } from "../components/cursor"

const RelatedProject = ({data}) => {
    return (
        <Link to={data.url} className="related-item" onMouseEnter={EnteringView} onMouseLeave={LeavingView}>
            <div className='content'>
                <h4>{data.client}</h4>
                <p className='lede'>{data.title}</p>
            </div>
            <div className='image-container'>
                <img className='cover-image' src={data.image} alt='' />
            </div>
        </Link>
    )
}

export default RelatedProject